

import {Component, Mixins, Ref, Watch} from "vue-property-decorator";
import GenelIslemFormWizard, {GenelIslemEntity} from "@/components/forms/form-wizard/eicrapro/GenelIslemFormWizard.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import EvrakEkleForm from "@/components/eicrapro/EvrakEkleForm.vue";
import {Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import PersonelPicker from "@/components/pickers/PersonelPicker.vue";
import {VForm} from "@/types";
import FormButton from "@/components/dialogs/FormButton.vue";
import GonderilecekEvrakList from "@/components/lists/GonderilecekEvrakList.vue";
import {ProgramPaths} from "@/enum/ProgramPaths";

@Component({
  components: {GonderilecekEvrakList, FormButton, PersonelPicker, EvrakEkleForm, FormDialog, GenelIslemFormWizard}
})

export default class EvrakGonderFormWizard extends Mixins(SimpleInputMixin) {
  @Ref('form') readonly form!: VForm;
  @Ref('genel-form') genelForm!: GenelIslemFormWizard;

  localValue: GenelIslemEntity = new GenelIslemEntity();
  valid = false;
  onFly = false;
  stepperHeaders = [
    "Listelenecek Takipler",
    "Takipleri Seç",
    "Evrak Gönder",
    "İşlemler Sıraya Alındı"
  ];
  secilenHeaders = [
    {text: "Birim Adı", value: "birimAdi"},
    {text: "Dosya Esas No", value: "dosyaNo"},
    {text: "Açılış Tarihi", value: "dosyaAcilisTarihi"},
    {text: "Dosya Durumu", value: "dosyaDurumAciklama"},
    {text: "İşlemler", value: "actions", align: 'center', sortable: false}
  ];

  @Watch('localValue.secilen_takipler')
  onTakiplerChange() {
    this.localValue.secilen_takipler.forEach(takip => {
      // @ts-ignore
      if (!takip.evraklar) {
        // @ts-ignore
        takip.evraklar = [];
        this.input();
      }
    })
  }

  onAddAll(event: any) {
    this.localValue.secilen_takipler.forEach((takip: any) => {
      if (takip.evraklar && takip.evraklar.length > 0) {
        takip.evraklar.push(event);
      } else {
        takip.evraklar = [];
        takip.evraklar.push(event);
      }
    })
    this.input();
  }

  // dosya kaydetme hatası düzeltilene kadar kaldırma
  waitThisLong(saniye: number) {
    return new Promise(resolve => setTimeout(resolve, saniye * 1000))
  }

  async createTask() {
    if (this.form.validate()) {
      this.onFly = true;
      let tasks: Array<Task> = [];
      for (let takip of this.localValue.secilen_takipler) {
        // @ts-ignore
        for (let evrak of takip.evraklar) {
          let formData = new FormData();
          formData.append('aciklama', evrak.aciklama);
          formData.append('name', evrak.dosyaData.name);
          formData.append('file', evrak.dosyaData.dosya);
          formData.append('hash', evrak.dosyaData.hash);
          formData.append('mime_type', evrak.dosyaData.type);
          let evrakResponse = await this.$http.post('/api/v1/file', formData);
          evrak.dosyaData.file_res = evrakResponse.data;
          evrak.dosyaData.base64 = null;
          // dosya kaydetme hatası düzeltilene kadar kaldırma
          await this.waitThisLong(1);
          for (let ekEvrak of evrak.ekDosyalarData) {
            let formData = new FormData();
            formData.append('aciklama', ekEvrak.aciklama);
            formData.append('name', ekEvrak.name);
            formData.append('file', ekEvrak.dosya);
            formData.append('hash', ekEvrak.hash);
            formData.append('mime_type', ekEvrak.type);
            let ekEvrakResponse = await this.$http.post('/api/v1/file', formData);
            ekEvrak.file_res = ekEvrakResponse.data;
            ekEvrak.base64 = null;
            // dosya kaydetme hatası düzeltilene kadar kaldırma
            await this.waitThisLong(1);
          }
        }
        let task: Task = new Task();
        task.dosya_esas_no = takip.dosyaNo;
        task.birim_adi = takip.birimAdi;
        task.dosya_id = takip.dosyaId;
        task.birim_id = takip?.birimId;
        task.uyap_dosya_acilis_tarihi = takip.dosyaAcilisTarihi ? new Date(this.$uyapHelper.formatDate(takip.dosyaAcilisTarihi)).toString() : "";
        task.task_type_id = TaskTuru.evrak_gonder;
        //@ts-ignore
        task.data = takip.evraklar;
        tasks.push(task);
      }
      await this.$store.dispatch('postTasks', tasks);
      await this.$router.push(ProgramPaths.eicrapro + '/islemler');
      this.genelForm.activeStep++;
      this.onFly = false;
    }
  }
}
