var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('genel-islem-form-wizard',{ref:"genel-form",attrs:{"stepper-headers":_vm.stepperHeaders,"on-fly":_vm.onFly,"success-message":"Yeni evrak gönderme işlemi için"},on:{"input":_vm.input},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},[_c('span',{attrs:{"slot":"secilenTakip"},slot:"secilenTakip"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"ma-auto"},[_c('v-col',{attrs:{"cols":"12","align":"right"}},[_c('v-btn',{staticClass:"mx-2 shadow-none elevation-0 eup-bg-orange",attrs:{"disabled":_vm.onFly,"dark":!_vm.onFly,"medium":""},on:{"click":_vm.createTask}},[_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-document-arrow-right-outline")]),_c('span',{staticStyle:{"font-size":"xx-small"}},[_vm._v(" Seçili Evrakları Gönder ")])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"text-subtitle-1"},[_vm._v("Seçilen Takipler")]),_c('v-spacer'),_c('v-col',{staticClass:"mb-3",attrs:{"align":"right"}},[_c('form-button',{attrs:{"default-value":{
                    seciliTur: null,
                    aciklama: null,
                    dosya: null,
                    dosyaData: {base64: null, name: null, type: null},
                    ekDosyalar: [],
                    ekDosyalarData: []
                  },"label":"Bütün Dosyalara Ekle","title":"Bütün Dosyalara Evrak Ekle","medium":"","dark":""},on:{"success":function($event){return _vm.onAddAll($event)}},scopedSlots:_vm._u([{key:"default",fn:function({formData,onSuccess,onClose}){return [_c('evrak-ekle-form',{on:{"onSuccess":onSuccess,"onClose":onClose},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}])})],1)],1),_c('v-data-table',{staticStyle:{"border":"#c7c7c7 solid 1px"},attrs:{"items":_vm.localValue.secilen_takipler,"headers":_vm.secilenHeaders,"hide-default-footer":"","disable-pagination":"","dense":""},scopedSlots:_vm._u([{key:"item.acilis_tarihi",fn:function({item}){return [_vm._v(" "+_vm._s(_vm.$helper.tarihSaatStr(item.acilis_tarihi))+" ")]}},{key:"item.actions",fn:function({item}){return [_c('form-button',{attrs:{"title":item.birimAdi + ' - ' +item.dosyaNo+' Dosyasının Gönderilecek Evrakları',"is-reset-local-value-on-close":false,"label":"Evrak Ekle","width":"700","small":"","dark":""},on:{"success":_vm.input},scopedSlots:_vm._u([{key:"default",fn:function({onSuccess,onClose}){return [_c('gonderilecek-evrak-list',{on:{"onSuccess":onSuccess,"onClose":onClose},model:{value:(item.evraklar),callback:function ($$v) {_vm.$set(item, "evraklar", $$v)},expression:"item.evraklar"}})]}}],null,true)})]}}])})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }